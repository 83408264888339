import React from "react";
import CtaBanner from "../../components/cta-banner";
import Layout from "../../components/layout";
import ServicesHero from "../../components/services/hero";
import ServiceCardSmall from "../../components/serviceCardSmall";
import Container from "react-bootstrap/Container";
import Link from "../../components/link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import Faq from "../../components/services/faq";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Review from "../../components/review";

const SocialMediaCourse = () => {
	const data = useStaticQuery(graphql`
		query {
			logoImg: file(relativePath: { eq: "logo.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
						quality: 100
					)
				}
			}
			heroImg: file(
				relativePath: { eq: "services/social-media-training-hero.jpg" }
			) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						formats: [AUTO, WEBP]
						quality: 100
					)
				}
			}
			inspiredImg: file(
				relativePath: { eq: "services/social-media-course/inspired.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						formats: [AUTO, WEBP]
						quality: 100
					)
				}
			}
			knowImg: file(
				relativePath: { eq: "services/social-media-course/know.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						formats: [AUTO, WEBP]
						quality: 100
					)
				}
			}
			learnImg: file(
				relativePath: { eq: "services/social-media-course/learn.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						formats: [AUTO, WEBP]
						quality: 100
					)
				}
			}
			tPImg: file(
				relativePath: {
					eq: "services/social-media-course/social-media-training-planner.jpg"
				}
			) {
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						formats: [AUTO, WEBP]
						quality: 100
					)
				}
			}
			launchKitImg: file(
				relativePath: { eq: "services/social-media-course/Your-Launch-Kit.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						formats: [AUTO, WEBP]
						quality: 100
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
					company
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pageUrl = "/services/social-media-photography-course";
	const companyName = data.site.siteMetadata.company;
	const schemaImg = data.heroImg.publicURL;
	const logoImg = data.logoImg.publicURL;

	const faqs = [
		{
			question: "Lesson 1",
			answer:
				"You'll learn the types of images you can create on your smart phone. The reasons you might use them. Types of backgrounds and props and how to use them. You'll learn about light and composition and setting up your smart phone to take images. Finally the dos and don'ts of capturing the perfect image.",
		},
		{
			question: "Lesson 2",
			answer:
				"You'll learn how  to create a flat lay image for your business, using your smart phone. Using props and backgrounds you've selected. This is the time to have fun and try different things.",
		},
		{
			question: "Lesson 3",
			answer:
				"In this final lesson you'll learn how to edit your picture on your phone using in phone editing and Lightroom for mobile.",
		},
	];

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Social Media Photography Course",
				item: `${siteUrl}/services/social-media-photography-course`,
			},
		],
	};

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#social-media-photography-course`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description:
			"Learn how to take stunning BESPOKE business images for your social media feeds from your smartphone.",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: "24",
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
	};
	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="Social Media Photography Course | Sarah Bennett Photography"
				description="Learn how to take stunning BESPOKE business images for your social media feeds from your smartphone."
				language="en"
				canonical="https://www.sarah-bennett.com/services/social-media-photography-course"
				openGraph={{
					url: "https://www.sarah-bennett.com/services/social-media-photography-course",
					title:
						"Social Media Photography Course East & West Sussex | Sarah Bennett Commerical Photography",
					description:
						"Learn how to take stunning BESPOKE business images for your social media feeds from your smartphone.",
					images: [
						{
							url: `${data.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Social Media Photography Course in East and West Sussex",
						},
					],
				}}
			/>
			<ServicesHero
				headline={["Social Media", <br />, "Photography Course"]}
				subheadline="Learn how to take stunning BESPOKE business images for your social	media feeds from your smartphone."
				img={data.heroImg.childImageSharp.gatsbyImageData}
				showBtn={true}
				url="/courses/smart-phone-photography-course"
				btnText="Take the course"
			/>
			<section>
				<Container>
					<Row>
						<Col xs={12} md={4} className="mb-5 mb-md-0">
							<ServiceCardSmall
								img={data.learnImg.childImageSharp.gatsbyImageData}
								name={[
									<span className="text-center text-secondary">
										<p>Improve the quality of your social media feeds. </p>
									</span>,
								]}
								url=""
								hideBtn={true}
								hideDesc={true}
							/>
						</Col>
						<Col xs={12} md={4} className="mb-5 mb-md-0">
							<ServiceCardSmall
								img={data.knowImg.childImageSharp.gatsbyImageData}
								name={[
									<span className="text-center text-secondary">
										<p>Get more clicks, attention and online sales.</p>
									</span>,
								]}
								url=""
								hideBtn={true}
								hideDesc={true}
							/>
						</Col>
						<Col xs={12} md={4} className="mb-5 mb-md-0">
							<ServiceCardSmall
								img={data.inspiredImg.childImageSharp.gatsbyImageData}
								name={[
									<span className="text-center text-secondary">
										<p>Save money by DYing your content from your phone.</p>
									</span>,
								]}
								url=""
								hideBtn={true}
								hideDesc={true}
							/>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="pt-4 pt-md-7">
				<Container>
					<Row>
						<Col>
							<div className="float-md-start floating-images pe-md-4 pt-md-3 mb-4 mb-md-3 mb-lg-0">
								<StaticImage
									src="../../images/services/social-media-course/social-media-training-planner.jpg"
									alt="Social Media Planner"
									quality="100"
									placeholder="blurred"
									layout="CONSTRAINED"
									formats={["auto", "webp"]}
								/>
							</div>
							<p>
								As a business owner, you know having an amazing social media
								presence is essential. After all, social media is one of the
								biggest marketing platforms around, with photo posts attracting
								some of the highest engagement rates across every network.
							</p>
							<p className="fw-bold">
								Photo posts get 39% more engagement than links, videos or text
								posts.
							</p>
							<span>
								Source:{" "}
								<a href="https://buffer.com/resources/7-facebook-stats-you-should-know-for-a-more-engaging-page/ ">
									Buffer.com
								</a>
							</span>{" "}
							<p>
								It’s no wonder almost every business turns to platforms like
								Instagram, Pinterest and Facebook to promote their products. But
								with so much competition vying for the eyes of your target
								audience, how can you ensure that your products get the
								attention they deserve?{" "}
							</p>
							<p>
								By standing out with incredible products and bespoke social
								media photos.{" "}
							</p>
							<p>
								This comprehensive social media photography course will show you
								just what you need to make your products stand out and look
								their very best online, all from the device in your pocket -
								your phone.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<Review
				author="Anne Deacon"
				company="Russ Deacon Kitchens"
				quote="Just completed an excellent smartphone photography session with Sarah Bennett commercial Photography. So many tips and information; I found settings on my phone I didn’t know were there !! I have been experimenting with contrast, exposure and saturation on one of my recent pictures, and it's amazing how just altering a few settings makes so much difference. Thank you, Sarah. I would have happily listened to Sarah for another hour!"
			/>
			<section className="pt-4">
				<Container>
					<Row>
						<Col>
							<div className="float-md-end floating-images ps-md-4 pt-md-3 mb-4 mb-md-0 pb-md-0">
								<StaticImage
									src="../../images/services/social-media-course/on-phone.jpg"
									alt="On The Phone"
									quality="100"
									placeholder="blurred"
									layout="CONSTRAINED"
									formats={["auto", "webp"]}
								/>
							</div>
							<h2 className="pb-2 ">
								Incredible smartphone snaps in three short lessons
							</h2>
							<p>
								Now, you may already have some idea of the basics, good
								lighting, for example. But yet your photos still don’t seem to
								speak for your brand or represent you as you’d like?
							</p>
							<p>
								Have no fear - to stand out; you don’t need to break the bank,
								you don’t need sophisticated, expensive equipment or fancy
								gadgets, a simple smartphone with a decent camera is enough to
								give you the stunning bespoke images you need.
							</p>{" "}
							<p>
								The three short lessons in this course aim to teach you how easy
								it is to do just this, giving you the basics you need to take
								you from amateur to superstar social media photographer in no
								time.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="mt-5">
				<Container>
					{" "}
					<Row>
						<Col className="mb-md-6">
							<h1 className="text-start text-md-center">
								Smartphone photography basics
							</h1>
							<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								To transform your social media feed into an attention-grabbing,
								professional business presence, all you need to know is what you
								can do with your existing resources - your smartphone, the props
								available, simple editing software, and the natural light
								seeping in through your window.
							</p>
							<p>This course will help you:</p>
							<ul className="course-list-lg ps-1 ps-md-3">
								<li>
									Understand the different types of images to use on social
									media
								</li>
								<li>
									Understand camera settings for obtaining different results
								</li>
								<li>Use simple props to make your photos come to life</li>
								<li>Create attractive flat lays that are share-worthy</li>
								<li>Learn how to edit your photos using just your phone</li>
								<li>Figure out the Do’s and Don’ts of business photography</li>
								<li>
									Take advantage of natural lighting to give you studio-quality
									photos
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-6">
				<Container>
					<Row>
						<Col>
							<div className="float-md-start floating-images pe-md-4 pt-md-3 mb-4 mb-md-0">
								<StaticImage
									src="../../images/services/social-media-course/working-shot.jpg"
									alt="working Shot"
									quality="100"
									placeholder="blurred"
									layout="CONSTRAINED"
									formats={["auto", "webp"]}
								/>
							</div>

							<h2 className="pb-2 ">Learn from the best in the business</h2>
							<p>
								Sarah Bennett, the founder of Sarah Bennett Commercial
								Photography, has been an avid photographer for years. Leaving
								behind a successful career in marketing and advertising, Sarah
								took up commercial photography professionally because of her
								sheer passion for the art.
							</p>
							<p>
								As an expert at
								<Link to="/services/business-branding">
									{" "}
									business branding{" "}
								</Link>
								and
								<Link to="/services/personal-branding">
									{" "}
									personal branding{" "}
								</Link>
								photography, Sarah designed this social media photography course
								with all the needs and challenges faced by her clients in mind.
								Clients who struggled to make a mark for themselves before
								working with her.
							</p>
							<p>
								To transform your social media feed into an attention-grabbing,
								professional business presence, all you need to know Is what you
								can do with your existing resources - your smartphone, the props
								available, simple editing software, and the natural light
								seeping in through your window.
							</p>
							<p>This course will help you:</p>
							<ul className="course-list-lg ps-1 ps-md-3">
								<li>Tell a story with your images</li>
								<li>
									Use daily objects as backgrounds or props to make your photos
									pop
								</li>
								<li>Create photo content that connects with your audience</li>
							</ul>
							<p className="pt-4">
								All while getting you the social media attention you deserve for
								the daily hard work you put into your products and services.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<Review
				author="Sarah Bennett"
				company="Owner"
				quote={[
					"With years of commercial photography experience behind me working with some well-known brands as well as small local businesses, I understand just how crucial your product photos are to the success of your business. Be it",
					<Link to="/services/food-photography"> food photography </Link>,
					",",
					<Link to="/services/product-photography"> product photography </Link>,
					", or any other niche that you need help with, our social media photography course covers all the tips and tricks you’ll need to give your social media feed an edge over your competitors.",
				]}
			/>
			<section>
				<Container>
					<Row>
						<Col className="mb-md-6">
							<h1 className="text-start text-md-center">
								Here is a quick taster of what’s coming up in the course
							</h1>
							<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
						</Col>
					</Row>
				</Container>
			</section>
			<Faq faqs={faqs} hideHeader={true} />
			<section className="pt-6">
				<Container>
					<Row>
						<Col className="mb-md-4">
							<h1 className="text-start text-md-center">
								A Taster Of The Course From Lesson 1
							</h1>
							<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-6 pb-6">
				<Container>
					<Row className="text-center">
						<Col>
							<iframe
								width="100%"
								height="500"
								src="https://www.youtube.com/embed/i9mfzvuVy-8"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="Imagine how fabulous your social media feeds with look with an understanding of how to create your own bespoke business imagery?"
				btnText="I'm Ready"
				url="/courses/smart-phone-photography-course"
			/>
		</Layout>
	);
};

export default SocialMediaCourse;
