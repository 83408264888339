import Link from "./link";
import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";

const ServiceCardSmall = ({
	name,
	description,
	url,
	img,
	hideBtn,
	hideDesc,
}) => {
	return (
		<Card className="border-0 pt-lg-6 pt-md-5">
			<Card.Img as={GatsbyImage} image={img} />
			<Card.Title
				className="text-start text-uppercase mt-5 fw-bold"
				style={{ fontSize: "1.75rem" }}
			>
				{name}
			</Card.Title>
			<Card.Body className={`px-0 ${hideDesc && "d-none"}`}>
				{description}
			</Card.Body>
			<Card.Footer className={`text-center px-0 ${hideBtn && "d-none"}`}>
				<Button variant="primary cta-btn" as={Link} to={url}>
					Read More
				</Button>
			</Card.Footer>
		</Card>
	);
};

export default ServiceCardSmall;
